import React, {useContext, useState} from "react";

import {Tabs, Tab} from "../../base/Tab";
import {Instruction} from "../../base";

import {ReportsComponentContainer} from "./styles";
import {CustomerCareReport} from "./CustomerCareReport";
import {DistributorReport} from "./DistributorReport";
import {ImplantReport} from "./ImplantReport";
import {DeviceTrackingReport} from "./DeviceTrackingReport";
import {ExplantsInvestigationReport} from "./ExplantsInvestigationReport";
import {BIAnnualReport} from "./BIAnnualReport";
import {useTranslation} from "react-i18next";
import {UserContext} from "../../../contexts/UserContext";
import {useSelector} from "react-redux";


export const ReportsComponent = () => {
    const {t} = useTranslation();
    const [currentReport, setCurrentReport] = useState();

    const featureFlag = useSelector((state) => state.featureFlagSlice.features);
    let isFeatureDeviceTrackingActive = featureFlag && featureFlag.filter((feat)=> feat.feature === "device_tracking")[0].status;

    const {canAccessCustomerCareReport, canAccessDistributorReport, canAccessImplantReport, canAccessDeviceTrackingPMS, canAccessExplantInvestigationPMS} =
        useContext(UserContext);
    return (
        <ReportsComponentContainer>
            <Instruction>{t("reports.instruction")}</Instruction>
            <Tabs
                id="ReportPicker"
                onSelect={setCurrentReport}
                activeKey={currentReport}
            >
                {canAccessCustomerCareReport() && (
                    <Tab eventKey="customerCare" title={t("reports.customerCare.name")}>
                        <CustomerCareReport/>
                    </Tab>
                )}
                {canAccessDistributorReport() && (
                    <Tab eventKey="distributor" title={t("reports.distributor.name")}>
                        <DistributorReport/>
                    </Tab>
                )}
                {canAccessImplantReport() && (
                    <Tab eventKey="implants" title={t("reports.implants.name")}>
                        <ImplantReport/>
                    </Tab>
                )}
                {(canAccessDeviceTrackingPMS() && isFeatureDeviceTrackingActive === 1)  && (
                    <Tab eventKey="deviceTracking" title={t("reports.deviceTracking.name")}>
                        <DeviceTrackingReport/>
                    </Tab>
                )}
                {(canAccessExplantInvestigationPMS() && isFeatureDeviceTrackingActive === 1)  && (
                    <Tab eventKey="deviceTrackingInvestigation" title={t("reports.explantsInvestigation.name")}>
                        <ExplantsInvestigationReport/>
                    </Tab>
                )}
                {(canAccessExplantInvestigationPMS() && isFeatureDeviceTrackingActive === 1)  && (
                    <Tab eventKey="BIAnnual" title={t("reports.BIAnnual.name")}>
                        <BIAnnualReport/>
                    </Tab>
                )}
            </Tabs>
        </ReportsComponentContainer>
    );
};
