import React from "react";
import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";

export const FormComponent = ({formik, formFields, action}) => {
    const {t} = useTranslation();
    const handlerError = (name) => formik.errors[name] && formik.touched[name] ? (
        <Box sx={{color: 'red', fontSize: 12}}>{formik.errors[name]}</Box>) : null;

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                {formFields.map(({name, type, label, value, options, properties, ...props}) => {
                    switch (type) {
                        case "select" :
                            return (
                                <Box my={3} display="flex" justifyContent="space-between" alignItems="center">
                                    <Box sx={{width: "40%"}}><label htmlFor={name}>{label}</label></Box>
                                    <Box sx={{width: "60%"}}>
                                        <select name={name} onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                value={formik.values[name]} className="custom-input"
                                                style={{borderRight: '16px solid transparent'}} disabled={properties?.active === true}>
                                            <option value="" disabled hidden></option>
                                            {options.map((option, idx) => (
                                                <option key={idx} value={option.value}>{option.label}</option>
                                            ))}
                                        </select>
                                        <Box ml={2} mt={1}>
                                            {handlerError(name)}
                                        </Box>
                                    </Box>
                                </Box>)
                        default:
                            return (
                                <Box my={3} display="flex" justifyContent="space-between" alignItems="center">
                                    <Box sx={{width: "40%"}}><label htmlFor={name}>{label}</label></Box>
                                    <Box sx={{width: "60%"}}>
                                        <input name={name} type={type} onBlur={formik.handleBlur}
                                               onChange={formik.handleChange}
                                               value={formik.values[name]} className="custom-input"/>
                                        <Box ml={2} mt={1}>
                                            {handlerError(name)}
                                        </Box>

                                    </Box>
                                </Box>
                            )
                    }
                })}
            </form>
            <Box display="flex" justifyContent="flex-end" mt={6}>
                {formik.values.id &&  <button className="custom-button btn-danger" onClick={()=> action.delete(formik.values.id)}>{t("delete")}</button> }
                {<button className="custom-button bgColor-primary mx-3" onClick={()=> formik.submitForm()}>{formik.values.id !== "" ? t("update") : t("saved")}</button>}
                <button className="custom-button" onClick={()=> action.close()}>{t("cancel")}</button>
            </Box>
        </>

    )
}