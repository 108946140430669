import {BootstrapProvider} from "@bootstrap-styled/provider";
import React from "react";
import {HashRouter as Router, Redirect, Route, Switch,} from "react-router-dom";
import {APIContextProvider} from "./contexts/APIContext";
import {AzureAuthProvider} from "./contexts/AzureAuthContext";
import {ConfigContextProvider} from "./contexts/ConfigContext";
import {NavigationContextProvider} from "./contexts/NavigationContext";
import {UserContextProvider} from "./contexts/UserContext";
import LoginPage from "./pages/login";
import ReportsPage from "./pages/reports";
import SearchPage from "./pages/search";
import UsersPage from "./pages/users";
import DistributorMaintenance from "./pages/settings/DistributorMaintenance";
import styles from "./styles";
import AppStyle from "./styles/global";
import useAwsRumClient from "./hooks/useAwsRumClient";

// import makeServer from "./__mocks__/server";
// makeServer();

function App() {
  useAwsRumClient();
  return (
    <Router>
      <BootstrapProvider theme={styles}>
        <ConfigContextProvider>
          <AzureAuthProvider>
            <APIContextProvider>
              <UserContextProvider>
                <NavigationContextProvider>
                  <AppStyle/>
                  <Switch>
                    <Route exact path="/">
                      <Redirect to="/search"/>
                    </Route>
                    <Route exact path="/login" component={LoginPage}/>
                    <Route exact path="/search" render={(props) => <SearchPage {...props} />}/>
                    <Route exact path="/reports" component={ReportsPage}/>
                    <Route exact path="/users" component={UsersPage}/>
                    <Route exact path="/settings/distributorMaintenance" component={DistributorMaintenance}/>
                  </Switch>
                </NavigationContextProvider>
              </UserContextProvider>
            </APIContextProvider>
          </AzureAuthProvider>
        </ConfigContextProvider>
      </BootstrapProvider>
    </Router>
  );
}

export default App;
