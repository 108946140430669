import { useTranslation } from "react-i18next";
import React, { createContext, useContext } from "react";

import { FullTransactionHistory } from "../modals/FullTransactionHistory";
import { PatientErrors } from "../modals/PatientErrors";
// import { SendEmailOrGeneratePassword } from "../modals/SendEmailOrGeneratePassword";
import { ExtendWarrantyModal } from "../modals/ExtendWarrantyModal";
import { DeleteUserModal, UserDetailsModal } from "../modals/UserDetailsModal";

import { UserContext } from "../contexts/UserContext";
import { ReSendEmailModal } from "../modals/ReSendEmail";
import { useSelector } from "react-redux";

export const NavigationContext = createContext({});

export const NavigationContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const deviceTrackingStatus = useSelector(
    (state) => state.deviceTrackingStatusSlice
  );
  const {
    canAccessSearchModule,
    canAccessReportModule,
    canAccessUserModule,
    canAccessSettingModule,

    canAddUser,
  } = useContext(UserContext);

  const translatedOptions = (prefix, options) =>
    Object.fromEntries(options.map((o) => [o, t(`${prefix}.${o}`)]));
  return (
    <NavigationContext.Provider
      value={{
        defaultUrl: "/",
        loginPath: "/login",
        search: {
          defaultCriteria: "email",
          criterias: {
            email: t("search.criteria.email"),
            serial: t("search.criteria.serial"),
            eserial: t("search.criteria.eserial"),
          },
          criteriasPatterns: {
            email: /^[^\s@]+@[^\s@]+$/,
            serial: /^\d{8}-\d{2}$/,
            eserial: /^\d{15}$/,
          },
        },
        objectOptions: {
          implantSide: translatedOptions("implant.side", ["L", "R"]),
          incisionKind: translatedOptions(
            "implant.incisionKind",
            [1, 2, 3, 4, 5, 0]
          ),
          implantPlacement: translatedOptions(
            "implant.placement",
            [1, 2, 3, 4, 0]
          ),
          implantIndication: translatedOptions(
            "implant.indication",
            [1, 2, 3, 4, 0]
          ),
          implantStatus: translatedOptions(
            "implant.status",
            deviceTrackingStatus?.canChangeStatusTo || []
          ),
          implantSource: translatedOptions(
            "implant.source",
            [1, 2, 3, 4, 5, 0]
          ),
          patientType: translatedOptions("patient.type", [1, 2, 0]),
        },
        actions: {
          home: { name: t("action.home"), href: "/" },
          search: {
            name: t("action.search"),
            href: "/search",
            icon: "search",
            access: () => canAccessSearchModule(),
          },
          reports: {
            name: t("action.reports"),
            href: "/reports",
            icon: "reports",
            access: () => canAccessReportModule(),
          },
          users: {
            name: t("action.users"),
            href: "/users",
            icon: "user",
            access: () => canAccessUserModule(),
          },
          settings: {
            name: t("action.settings"),
            href: "/settings/distributorMaintenance",
            icon: "cog",
            access: () => canAccessSettingModule(),
          },
          /*
          userEmailOrPassword: {
            name: t("action.userEmailOrPassword"),
            icon: "email",
            modal: SendEmailOrGeneratePassword,
          },
          */
          fullTransactionHistory: {
            name: t("action.fullTransactionHistory"),
            icon: "truck",
            modal: FullTransactionHistory,
          },
          patientErrors: {
            name: t("action.patientErrors"),
            icon: "errors",
            modal: PatientErrors,
          },
          ResendEmail: {
            name: t("action.ResendEmail"),
            icon: "email",
            modal: ReSendEmailModal,
          },
          extendWarranty: {
            name: t("action.extendWarranty"),
            icon: "warranty",
            modal: ExtendWarrantyModal,
          },
          userAdd: {
            name: t("action.addUser"),
            modal: UserDetailsModal,
            access: () => canAddUser(),
          },
          userEdit: {
            name: t("action.editUser"),
            modal: UserDetailsModal,
            access: () => canAddUser(),
          },
          deleteUser: {
            name: t("action.deleteUser"),
            modal: DeleteUserModal,
            access: () => canAddUser(),
          },
        },
        homeActions: ["search"],
        menuItems: ["search", "reports", "users", "settings"],
        patientActions: ["patientErrors", "ResendEmail"],
        implantActions: ["fullTransactionHistory"],
        userActions: ["userAdd"],
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};
