export const formDistributorCatalog =(t, data, formik)=> [
    {
        type: "text",
        name: "code",
        label: "Code"
    },
    {
        type: "text",
        name: "name",
        label: "Name"
    },{
        type: "select",
        name: "country",
        label: "Country",
        options: data.Countries.map((country, i)=> ({
            id: country.id, value: country.id, label: country.name
        })),
        properties:{
            active: formik.values.id !== ""
        }
    },{
        type: "select",
        name: "isTesting",
        label: "Is testing",
        options: [
            {label: "true", value: 1},
            {label: "false", value: 0}
        ]
    }
];