import React, {useContext} from "react";

import {APIContext} from "../../../../contexts/APIContext";
import {CountrySelect, Form, PatientTypeSelect, SubmitOrCancel, TextInput} from "../../../form";
import {DataDict, DataEntries} from "../../../data";
import {PatientContext} from "../../../../contexts/PatientContext";
import {UserContext} from "../../../../contexts/UserContext";
import {translateWithPrefix} from "../../../../formatters";
import {useEditable} from "../../../../hooks/useEditable";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import _ from 'lodash';
import fileDownload from "js-file-download";

export default function PatientPersonalInfoCard({title, data}) {
    const formState = useSelector(state => state.formSlice?.formValues);
    const {canEditPatient} = useContext(UserContext);
    const {countryById, postUserType} = useContext(APIContext);
    const {postPatient} = useContext(PatientContext);
    const {editable, EditToggle, setEditable} = useEditable();
    const canEdit = () => editable && canEditPatient();
    const {t} = useTranslation();
    const styled = {
        color: 'red',
        fontSize: 12
    }

    const changeUserType =()=>{

        if (_.size(data.implants) !== 0) return;
        if(data.type !== Number(formState.type)){

            let payload = {
                "email": data.email,
                "type": formState.type
            };

            postUserType(payload).then((response) => console.log(response))
        }
    }

    return (
        <Form
            onSubmit={postPatient}
            callbackFn={changeUserType}
            fields={["firstName", "lastName", "country"]}
            data={data}
            onFinishEdit={() => setEditable(false)}
        >
            <DataDict
                title={title}
                topRight={data.from_CDC ? null : canEditPatient() && <EditToggle/>}
                footer={canEdit() && <SubmitOrCancel disableIfUnchanged/>}
            >
                <DataEntries
                    data={data}
                    translationKey={"patient.data"}
                    format={{
                        type: translateWithPrefix("patient.type"),
                        country: countryById
                    }}
                    Wrapper={{
                        firstName: canEdit() && TextInput,
                        lastName: canEdit() && TextInput,
                        country: canEdit() && CountrySelect,
                        type: canEdit() && PatientTypeSelect,
                    }}
                    keys={["firstName", "lastName", "email", "country", "type", "source", "provider"]}
                />
                {data.from_CDC && (<>
                    <hr/>
                    <p style={styled}>{t('general.UserCDC')}</p></>)
                }
            </DataDict>
        </Form>
    );
}
