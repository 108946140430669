import React, { useContext } from "react";
import { Link as RRDLink, NavLink } from "react-router-dom";
import { NavigationContext } from "../../contexts/NavigationContext";
import { Icon } from "../icons";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { withModal } from "../modal";

const StyledLink = styled(RRDLink)`
  color: ${(props) => props.theme.$primary};
  text-decoration: none;
`;

const StyledNavLink = styled(NavLink)`
  color: ${(props) => props.theme.$primary};
  text-decoration: none;
`;

const StyledIcon = styled(Icon)`
  margin: 0px 5px;
`;

const StyledA = styled.a`
  color: ${(props) => props.theme.$primary};
  margin: 0px 5px;

  &:hover {
    color: ${(props) => props.theme.$primary};
  }
`;

const LinkContent = ({ icon, name }) => (
  <>
    {icon && <StyledIcon className="icon" icon={icon} />}
    <span className="text">{name}</span>
  </>
);

export const Link = ({
  to = "#",
  onClick,
  href,
  name,
  icon,
  action,
  modal,
  nav,
  back,
  big = false,
  children,
  ...props
}) => {
  const { actions } = useContext(NavigationContext);
  const { t } = useTranslation();
  let Component;

  if (back) name = `< ${t("general.back")}`;

  if (action in actions) {
    ({ name, href, icon, modal } = actions[action]);
  }

  Component = onClick || modal ? StyledA : nav ? StyledNavLink : StyledLink;

  if (!href) {
    href = to;
  }

  if (onClick) {
    href = "#";
  }

  if (modal) {
    href = "#";
    Component = withModal(Component, modal);
  }

  return (
    <Component to={href} onClick={onClick} {...props}>
      {children || <LinkContent name={name} icon={icon} />}
    </Component>
  );
};
